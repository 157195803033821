.App {
  text-align: center;
}

#nprogress .bar {
  background: #000 !important;
}

#nprogress .peg {
  box-shadow: 0 0 10px #000, 0 0 5px #000;
}


.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.f-text-logo {
  /* Findable / Gradient */
  background: linear-gradient(
    91.77deg,
    #453cfe 7.06%,
    #18acfe 50.48%,
    #0173fe 91.55%
  );
  font-weight: 700;
  margin-left: 8px;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  letter-spacing: 1.5px;
}

.f-logo {
  /* Rectangle 5574 */
  position: relative;
  width: 178px;
  height: 30px;
  left: 80px;
  top: 25px;
  /* Findable / Gradient */
  background: linear-gradient(
    91.77deg,
    #453cfe 7.06%,
    #18acfe 50.48%,
    #0173fe 91.55%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.snackbar-customize {
  width: 325px !important;
  min-height: 68px !important;
  display: flex;
  /* gap: 30px; */
  color: #0173fe !important;
  margin-top: 120px;
  border: 1px solid #e8f0ff !important;
  background-color: #e8f0ff !important;
  border-radius: 20px !important;
  font-size: 14px !important;
  box-shadow: none !important;
  font-weight: 500 !important;
}

.SnackbarItem-wrappedRoot .snackbar-customize {
  display: flex !important;
  flex-direction: inherit !important;
  flex-wrap: nowrap !important;
  border: 1px solid #e8f0ff !important;
}
/* .Toastify__toast{
  max-width: 398px !important;
  min-height: 68px !important;
  color: #0173FE !important;
  margin-top: 120px;
  border: 1px solid #E8F0FF !important;
  background-color: #E8F0FF !important;
  border-radius: 20px !important;
  font-size: 15px !important;
  box-shadow: none !important;
  font-weight: 600 !important;
} */

/* .ps__thumb-y { 
  width: 4px !important;   
}

.ps__rail-y{
  width: 4px !important;  
} */

.ql-toolbar.ql-snow {
  min-height: 48px;
  background-color: #fbfbfb;
  border-color: #a3abbf !important;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.ql-container.ql-snow {
  min-height: 229px !important;
  border-color: #a3abbf !important;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.ql-editor {
  white-space: normal !important;
  font-family: "DM Sans" !important;
}

.ql-editor p {
  line-height: 24px !important;
  font-size: 16px !important;
}

.Toastify__close-button {
  color: #0173fe !important;
  padding: 5px !important;
}

.custom-static-calendar .MuiCalendarPicker-root {
  background-color: #fff !important;
  width: 100%;
  margin: 0px !important;
}

.custom-static-calendar .css-epd502 {
  width: 384px !important;
}

.custom-static-calendar .css-1dozdou {
  margin: 0px !important;
  padding: 0px !important;
  margin-top: 2px !important;
  margin-bottom: 2px !important;
}
.custom-static-calendar .css-1dozdou .css-1v994a0 {
  font-weight: 700 !important;
  font-size: 18px !important;
  line-height: 122.5% !important;
  color: #060a2d !important;
}

.custom-static-calendar .css-1n2mv2k {
  padding-top: 20px;
  gap: 45px !important;
}

.custom-static-calendar .css-1n2mv2k .MuiTypography-root {
  color: #060a2d !important;
  font-weight: 700 !important;
  font-size: 16px !important;
}

.custom-static-calendar .css-mvmu1r {
  gap: 20px !important;
}

.custom-static-calendar .MuiCalendarPicker-root .MuiPickersDay-dayWithMargin {
  background-color: #fff !important;
  margin: 2.5px;
  color: #082f4d;
}

/* enable / current days */
.custom-static-calendar .MuiCalendarPicker-root .MuiPickersDay-root {
  color: #060a2d !important;
  font-weight: 500 !important;
  font-size: 14px !important;
}

/* disbled past days */
.custom-static-calendar .MuiCalendarPicker-root .Mui-disabled {
  color: #d9dbe9 !important;
  font-weight: 500 !important;
  font-size: 14px !important;
}

.custom-static-calendar .MuiCalendarPicker-root .Mui-selected {
  /* background-color: #E8F0FF !important;   */
  margin: 2.5px;
  color: #0173fe !important;
}

.custom-static-calendar .MuiPickersDay-today {
  border: none !important;
}

.custom-static-calendar .MuiPickersArrowSwitcher-root .Mui-disabled {
  margin: 0px !important;
}

.custom-static-calendar .MuiPickersArrowSwitcher-button {
  border: 1px solid #dcdfe3 !important;
  border-radius: 4px !important;
  /* width: 32px;
  height: 32px;  */
  width: 44px !important;
  height: 35px !important;
}

.custom-static-calendar .MuiPickersArrowSwitcher-button .MuiSvgIcon-root {
  width: 16px;
  height: 16px;
}

.session-time-scroll::-webkit-scrollbar {
  width: 6px;
}
.session-time-scroll::-webkit-scrollbar-track {
  background: #f0f0f0;
  border-radius: 30px;
}
.session-time-scroll::-webkit-scrollbar-thumb {
  background: #d7d7d7;
  border-radius: 30px;
}

.no-margin p {
  margin: 0px !important;
}

.no-margin-ul ul,
ol {
  margin: 0px !important;
  padding-inline-start: 17px;
  padding: 0px;
}

.ql-indent-1 {
  margin-inline-start: 40px;
}

.ql-snow .ql-stroke {
  stroke-width: 1.5px !important;
}

.paymentContainer::-webkit-scrollbar {
  width: 4px;
}
.paymentContainer::-webkit-scrollbar-track {
  background: #fff;
}
.paymentContainer::-webkit-scrollbar-thumb {
  background: #e4e3e3;
  border-radius: 4px;
}

.snackbar-custome {
  min-height: 68px !important;
  color: #0173fe !important;
  margin-top: 120px;
  border: 1px solid #e8f0ff !important;
  background-color: #e8f0ff !important;
  border-radius: 20px !important;
  font-size: 14px !important;
  box-shadow: none !important;
  font-weight: 400 !important;

  display: flex !important;
  flex-direction: inherit !important;
  flex-wrap: nowrap !important;
}

/* client email messaging */
.thread-conversation-container::-webkit-scrollbar {
  width: 6px;
  border-radius: 30px;
}
.thread-conversation-container::-webkit-scrollbar-track {
  background: #f4f4f4;
}
.thread-conversation-container::-webkit-scrollbar-thumb {
  background: #e4e3e3;
  border-radius: 4px;
}

.readmore-content {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #656565;
}

.readmore-anchor {
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #0173fe;
  cursor: pointer;
}

.readmore-content-coach-offerlist {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #838383;
}

.readmore-content-coach-offerlist-2 {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #6e757c;
  white-space: pre-wrap !important;
}

.readmore-content-coach-offerlist-2 .show-more-less-clickable {
  text-decoration: none;
}

.readmore-content-coach-offerlist-3 {
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: #878787;
}

.readmore-content-appointment-details {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #838383;
}

.custom-static-calendar-customcolor .MuiCalendarPicker-root {
  background-color: #fafcff !important;
  width: 100%;
  margin: 0px !important;
  max-height: 386px !important;
  overflow: hidden !important;
}

.custom-static-calendar-customcolor
  .MuiCalendarPicker-root
  .MuiPickersDay-dayWithMargin {
  background-color: transparent !important;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 1px;
  margin-right: 1px;
  color: #082f4d;
}

.custom-static-calendar-customcolor .MuiCalendarPicker-root .Mui-selected {
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 1px;
  margin-right: 1px;
  color: #0173fe !important;
  border: 1px solid #0173fe !important;
  background-color: #e8f0ff !important;
}

.custom-static-calendar-customcolor .css-j7qwjs {
  background-color: #fafcff !important;
}

.custom-static-calendar-customcolor .css-1n2mv2k {
  padding-top: 20px;
  gap: 25px !important;
}

.custom-static-calendar-customcolor .css-1n2mv2k .MuiTypography-root {
  color: #060a2d !important;
  font-weight: 700 !important;
  font-size: 16px !important;
}

.custom-static-calendar-customcolor .MuiPickersArrowSwitcher-button {
  color: #0173fe !important;
}

.custom-static-calendar-customcolor .PrivatePickersSlideTransition-root {
  height: 380px !important;
  max-height: 380px !important;
}

.custom-static-calendar-customcolor .css-epd502 {
  height: 380px !important;
  max-height: 380px !important;
}

.custom-static-calendar-customcolor .PrivatePickersFadeTransitionGroup-root {
  overflow: hidden;
}

@media screen and (max-width: 899px) {
  .custom-static-calendar-customcolor .css-epd502 {
    height: 500px !important;
    max-height: 500px !important;
    /* border: 1px solid green !important; */
  }

  .custom-static-calendar-customcolor .css-epd502 {
    width: 100% !important;
    /* max-width: 350px !important;  */
  }

  .custom-static-calendar-customcolor .MuiCalendarPicker-root {
    background-color: #fafcff !important;
    width: 450px !important;
    margin: auto !important;
    max-height: 480px !important;
    /* border: 1px solid blue !important */
  }
  /*   

  .custom-static-calendar .PrivatePickersSlideTransition-root{  
    height: 350px !important;
    max-height: 350px !important;   
    border: 1px solid yellow !important
  }   */

  /* //// // */
  .custom-static-calendar .css-1n2mv2k {
    padding-top: 20px;
    gap: 20px !important;
  }

  .custom-static-calendar .css-mvmu1r {
    display: flex !important;
    gap: 22px !important;
  }

  /* enable / current days */
  .custom-static-calendar .MuiCalendarPicker-root .MuiPickersDay-root {
    color: #060a2d !important;
    font-weight: 500 !important;
    font-size: 14px !important;
  }

  /* disbled past days */
  .custom-static-calendar .MuiCalendarPicker-root .Mui-disabled {
    color: #d9dbe9 !important;
    font-weight: 500 !important;
    font-size: 14px !important;
  }

  .custom-static-calendar .css-1n2mv2k .MuiTypography-root {
    color: #060a2d !important;
    font-weight: 700 !important;
    font-size: 14px !important;
  }

  .css-163zzso {
    padding-top: 19px !important;
  }

  .custom-static-calendar .MuiPickersArrowSwitcher-button {
    margin-right: 20px !important;
  }

  .custom-static-calendar .MuiCalendarPicker-root .MuiPickersDay-dayWithMargin {
    background-color: #fafcff !important;
  }

  .custom-static-calendar .MuiCalendarPicker-root .Mui-selected {
    color: #0173fe !important;
    border: 1px solid #0173fe !important;
    background-color: #e8f0ff !important;
  }

  .custom-static-calendar .MuiPickersArrowSwitcher-root .Mui-disabled {
    margin-right: 20px !important;
  }

  .custom-static-calendar .css-1dozdou .css-1v994a0 {
    font-weight: 700 !important;
    font-size: 16px !important;
  }

  .custom-static-calendar .MuiPickersArrowSwitcher-root {
    margin-top: 3px;
  }
}

/* calendar css on small screen */
/* @media screen and (max-width: 650px) {
  .custom-static-calendar .css-epd502{
    width: 100% !important; 
    max-width: 350px !important; 
  }
  .custom-static-calendar .css-1n2mv2k{  
    padding-top: 20px !important;  
    margin: 0 !important;  
    gap: 10px !important
  }  

  .custom-static-calendar .css-mvmu1r { 
    display: flex !important;  
    gap: 15px !important
  }

  .custom-static-calendar .MuiCalendarPicker-root .MuiPickersDay-dayWithMargin{ 
    background-color: #fff !important;   
    margin: 0px !important;  
    color: #082F4D;    
  }  

} */

@media screen and (max-width: 550px) {
  .custom-static-calendar-customcolor .css-epd502 {
    height: 450px !important;
    max-height: 450px !important;
    /* border: 1px solid blue !important; */
  }

  .custom-static-calendar-customcolor .css-epd502 {
    width: 100% !important;
  }

  .custom-static-calendar-customcolor .MuiCalendarPicker-root {
    background-color: #fafcff !important;
    width: 315px !important;
    margin: auto !important;
    max-height: 450px !important;
    /* border: 1px solid red !important */
  }

  /* enable / current days */
  .custom-static-calendar .MuiCalendarPicker-root .MuiPickersDay-root {
    color: #060a2d !important;
    font-weight: 500 !important;
    font-size: 12px !important;
  }

  .custom-static-calendar .css-1n2mv2k .MuiTypography-root {
    color: #060a2d !important;
    font-weight: 700 !important;
    font-size: 12px !important;
  }

  .custom-static-calendar .css-1n2mv2k {
    padding-top: 20px !important;
    gap: 5px !important;
    /* border : 1px solid red; */
  }

  .custom-static-calendar .css-mvmu1r {
    gap: 7px !important;
    /* border : 1px solid red; */
  }

  .custom-static-calendar .MuiCalendarPicker-root .MuiPickersDay-dayWithMargin {
    background-color: #fafcff !important;
  }

  .custom-static-calendar .MuiCalendarPicker-root .Mui-selected {
    color: #0173fe !important;
    border: 1px solid #0173fe !important;
    background-color: #e8f0ff !important;
  }

  .custom-static-calendar .MuiCalendarPicker-root .Mui-disabled {
    color: #d9dbe9 !important;
  }

  .custom-static-calendar .MuiPickersArrowSwitcher-root .Mui-disabled {
    margin-right: 20px !important;
  }

  .custom-static-calendar .css-1dozdou .css-1v994a0 {
    font-weight: 700 !important;
    font-size: 14px !important;
  }

  .custom-static-calendar .MuiPickersArrowSwitcher-root {
    margin-top: 3px !important;
  }

  .Toastify__toast-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .snackbar-customize {
    width: 100% !important;
    max-width: 325px !important;
    min-height: 68px !important;
    color: #0173fe !important;
    margin-top: 120px;
    border: 1px solid #e8f0ff !important;
    background-color: #e8f0ff !important;
    border-radius: 20px !important;
    font-size: 14px !important;
    box-shadow: none !important;
    font-weight: 500 !important;
  }
}

.notistack-customize {
  border-radius: 8px !important;
}

.custom-snackbar-success {
  background-color: #00ab41 !important;
  font-family: "DM Sans" !important;
  font-size: 15px !important;
}

.custom-snackbar-error {
  background-color: #e92c2c !important;
  font-family: "DM Sans" !important;
  font-size: 15px !important;
}

.react-pdf__Page__canvas {
  margin: auto !important;
}

.notifications-scrollbar::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}
.notifications-scrollbar::-webkit-scrollbar-track {
  background: #fff;
}
.notifications-scrollbar::-webkit-scrollbar-thumb {
  background: #e4e3e3;
  border-radius: 4px;
}

.notifications-scrollbar::-webkit-scrollbar-thumb:hover {
  width: 8px !important; /* Increase the width on hover */
  height: 8px !important;
}

/* animate crating survey questions using ai */
@keyframes ellipsis {
  0% {
    content: ".";
  }
  33% {
    content: "..";
  }
  66% {
    content: "...";
  }
}

.animate-ellipsis::after {
  content: "...";
  animation: ellipsis 1s infinite;
  display: inline-block;
  width: 1em; /* Adjust the width as needed */
  text-align: left;
  vertical-align: bottom;
}

.tr {
  border: 2px solid blue;
  border-radius: 24px !important;
}

.invoice-client-new .MuiAutocomplete-paper {
  box-shadow: none !important;
}

.ql-snow .ql-editor img {
  max-width: 80% !important;
}

.ql-align-center {
  text-align: center !important;
}

.ql-align-right {
  text-align: right !important;
}

.treffas-webform-inner {
  font-weight: 700 !important;
  font-size: 16px !important;
  margin-left: 2px;
  color: #0173fe;
}

.roundedTableRow td {
  border-radius: 8px;
}


.editorjs-package, .editorjs-custom-button, .editorjs-subscribed{ 
  width: 100%;
} 

.ce-block__content{ 
  margin-top: 16px;
  margin-bottom: 16px;
}

.ce-block--selected .ce-block__content{ 
  background-color: #FFF;
}

.divided-border {
  border-bottom: 4px solid black; /* Set border bottom to be 4px thick and black */
  width: 100%; /* Adjust width of the element */
  position: relative; /* Ensure proper positioning of pseudo-elements */
}

.divided-border::before,
.divided-border::after {
  content: "";
  position: absolute;
  bottom: 0;
  height: 50%; /* Half of the border width */
  width: 50%; /* Half of the element width */
  border-bottom: 2px solid; /* Divide the border into 4 parts */
}

.divided-border::after {
  right: 0;
  border-color: red; /* Change color of the second half */
}

.ce-popover-item__icon .custom-icon {
  width: 15px !important;
  height: 15px !important;
}

.settings-website-iframe iframe{
  border: 1px solid #EAECEF;
  height: 1000px;
  border-radius: 12px;
}
 

