:root{
    --font-work-sans: "Work Sans", sans-serif;
    --font-alike: "Alike", serif;
    --font-mukta: "Mukta", sans-serif;
    --font-firo: "Fira Code", serif;
    --bg-black: #000;
    --color-orange: #D97855;
    --color-black: #000;
    --color-gray: #6E757C;  
    
    --font-nanum : "Nanum Myeongjo", sans-serif;
    --font-source : "Source Sans 3", sans-serif;
    --font-source_sans_3 : "Source Sans 3", sans-serif;
    --font-poppins : "Poppins", sans-serif;
    --font-alegreya_sans : "Alegreya Sans", sans-serif;
    --font-alegreya : "Alegreya", sans-serif;
    --font-archivo_narrow : "Archivo Narrow", sans-serif;
    --font-archivo : "Archivo", sans-serif;
    --font-biorhyme : "BioRhyme", serif;
    --font-cardo : "Cardo", serif;
    --font-cormorant : "Cormorant", serif;
    --font-eczar : "Eczar", serif;
    --font-fira_sans : "Fira Sans", sans-serif;
    --font-fraunces : "Fraunces", serif;
    --font-karla : "Karla", sans-serif;
    --font-libre_baskerville : "Libre Baskerville", serif;
    --font-libre_franklin : "Libre Franklin", sans-serif;
    --font-lora : "Lora", sans-serif;
    --font-manrope : "Manrope", sans-serif;
    --font-merriweather : "Merriweather", serif;
    --font-neuton : "Neuton", serif;
    --font-open_sans : "Open Sans", sans-serif;
    --font-pt_sans : "PT Sans", sans-serif;
    --font-raleway : "Raleway", sans-serif;
    --font-roboto : "Roboto", sans-serif;
    --font-source_serif_4 : "Source Serif 4", sans-serif;
    --font-space_mono : "Space Mono", monospace;
    --font-spectral : "Spectral", serif;
    --font-work_sans : "Work Sans", sans-serif;
    --font-lato: "Lato", sans-serif;
}  

.readmore-content-package-details {
    font-weight: 500 !important;
    font-size: 16px !important;
    line-height: normal !important;
    color: #19181B !important;
    line-height: normal !important;
}

.readmore-content-package-details span.show-more-less-clickable {
    color : var(--color-orange);
    text-decoration: none;
}
