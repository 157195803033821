.new-static-calendar .css-1dozdou {
  margin-left: 10px;
  margin-right: 10px;
}

.new-static-calendar .css-dhopo2 {
  min-height: 335px;
}

.new-static-calendar .MuiIconButton-edgeStart {
  border: 1px solid #dcdfe3;
  border-radius: 6px;
}

.new-static-calendar .MuiIconButton-edgeEnd {
  border: 1px solid #dcdfe3;
  border-radius: 6px;
}

.new-static-calendar .css-epd502 {
  height: 450px !important;
  max-height: 450px !important;
  background-color: #ffffff !important;
  border-radius: 12px !important;
  border: 1px solid #f0f0f0 !important;
  width: 100% !important;
}

.new-static-calendar .MuiPickerStaticWrapper-root {
  background-color: transparent;
}

.new-static-calendar .MuiCalendarPicker-root {
  width: 416px !important;
  margin: auto !important;
  max-height: 450px !important;
}

/* enable / current days */
.new-static-calendar .MuiCalendarPicker-root .MuiPickersDay-root {
  color: #060a2d !important;
  font-weight: 500 !important;
  font-size: 12px !important;
}

.new-static-calendar .css-1n2mv2k .MuiTypography-root {
  color: #060a2d !important;
  font-weight: 700 !important;
  font-size: 16px !important;
}

.new-static-calendar .css-1n2mv2k {
  padding-top: 5px !important;
  gap: 17px !important;
}

.new-static-calendar .css-mvmu1r {
  gap: 12px !important;
  margin-top: 13px;
}

.new-static-calendar .MuiCalendarPicker-root .MuiPickersDay-dayWithMargin {
  background-color: #fff !important;
  font-size: 16px !important;
  border: 1px solid #f2f2f2 !important;
  border-radius: 100px !important;
  padding: 19px !important;
}

.new-static-calendar .MuiCalendarPicker-root .Mui-disabled {
  color: #878787 !important;
  background-color: #f7f7f7 !important;
}

.new-static-calendar .MuiCalendarPicker-root .Mui-selected {
  color: #f8f9fa !important;
  border: 1px solid var(--color-black) !important;
  background-color: var(--color-black) !important;
}

.new-static-calendar .css-1dozdou .css-1v994a0 {
  font-weight: 700 !important;
  font-size: 17px !important;
}

.new-static-calendar .MuiCalendarPicker-root .MuiPickersDay-dayOutsideMonth {
  border: 1px solid #ffffff !important;
  color: #dbdbdb !important;
  background-color: #fff !important;
}

/* small screen */
@media screen and (max-width: 550px) {
  .new-static-calendar .css-1dozdou {
    margin-left: -13px;
    margin-right: -8px;
  }

  .new-static-calendar .css-dhopo2 {
    min-height: 335px;
  }

  .new-static-calendar .MuiIconButton-edgeStart {
    border: 1px solid #dcdfe3;
    border-radius: 6px;
  }

  .new-static-calendar .MuiIconButton-edgeEnd {
    border: 1px solid #dcdfe3;
    border-radius: 6px;
  }

  .new-static-calendar .css-epd502 {
    height: 450px !important;
    max-height: 450px !important;
    background-color: #ffffff !important;
    border-radius: 5px !important;
    border: 1px solid #f0f0f0 !important;
    width: 100% !important;
  }

  .new-static-calendar .MuiPickerStaticWrapper-root {
    background-color: transparent;
  }

  .new-static-calendar .MuiCalendarPicker-root {
    width: 320px !important;
    margin: auto !important;
    max-height: 450px !important;
  }

  /* enable / current days */
  .new-static-calendar .MuiCalendarPicker-root .MuiPickersDay-root {
    color: #060a2d !important;
    font-weight: 500 !important;
    font-size: 12px !important;
  }

  .new-static-calendar .css-1n2mv2k .MuiTypography-root {
    color: #060a2d !important;
    font-weight: 700 !important;
    font-size: 14px !important;
  }

  .new-static-calendar .css-1n2mv2k {
    padding-top: 5px !important;
    gap: 5px !important;
  }

  .new-static-calendar .css-mvmu1r {
    gap: 0px !important;
    margin-top: 13px;
  }

  .new-static-calendar .MuiCalendarPicker-root .MuiPickersDay-dayWithMargin {
    background-color: #fff !important;
    font-size: 14px !important;
    border: 1px solid #f2f2f2 !important;
    border-radius: 100px !important;
    padding: 19px !important;
    height: 10px !important;
    width: 10px !important;
  }

  .new-static-calendar .MuiCalendarPicker-root .Mui-disabled {
    color: #878787 !important;
    background-color: #f7f7f7 !important;
  }

  .new-static-calendar .MuiCalendarPicker-root .Mui-selected {
    color: #f8f9fa !important;
    border: 1px solid #0173fe !important;
    background-color: #0173fe !important;
  }

  .new-static-calendar .css-1dozdou .css-1v994a0 {
    font-weight: 700 !important;
    font-size: 17px !important;
  }

  .new-static-calendar .MuiCalendarPicker-root .MuiPickersDay-dayOutsideMonth {
    border: 1px solid #ffffff !important;
    color: #dbdbdb !important;
    background-color: #fff !important;
  }
}
